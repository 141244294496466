.footer {
  background: #edeff1;
  height: auto;
  padding-bottom: 30px;
  position: relative;
  width: 100%;
  border-bottom: 1px solid #cccccc;
  border-top: 1px solid #dddddd;
}

.footer p {
  margin: 0;
}

.footer h3 {
  border-bottom: 1px solid #bac1c8;
  color: #54697e;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  padding: 40px 0 10px;
  text-transform: uppercase;
  text-align: center;
}

.footer ul {
  list-style-type: none;
  padding: 0;
  margin: 16px;
  color: #7f8c8d;
  text-align: center;
}

.github-links li a {
  display: inline-block;
  padding-bottom: 4px;
  font-size: 14px;
  color: #78828d;
}

.newsletter-box {
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.send-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #bac1c8;
  border-radius: 3px;
  height: 40px;
  padding: 0;
  width: 50%;
  color: #fff;
  background-image: unset;
  background-color: #dcdcdc;
  box-shadow: 0 1px 0 #b4b3b3;
}

.send-button:hover {
  background: white;
  color: #7f8c8d;
}

#send-email {
  font-size: 18px;
  margin: 0 4px;
}

.social li {
  background: none repeat scroll 0 0 #b5b5b5;
  border: 2px solid #b5b5b5;
  border-radius: 50%;
  font-size: 16px;
  width: 36px;
  line-height: 32px;
  margin: 0 4px 0 4px;
  padding: 0;
  display: inline-block;
  text-align: center;

  transition: all 0.5s ease 0s;
}

.social li:hover {
  transform: scale(1.15) rotate(360deg);
}

.social li a {
  color: #edeff1;
  display: block;
}

/* Colors of the companies on hover. */
#facebook:hover {
  border: 2px solid #3b5998;
  background: #3b5998;
}

#twitter:hover {
  border: 2px solid #00aced;
  background: #00aced;
}

#skype:hover {
  border: 2px solid #12a5f4;
  background: #12a5f4;
}

#linkedin:hover {
  border: 2px solid #0077b5;
  background: #0077b5;
}

#github:hover {
  border: 2px solid black;
  background: black;
}

.footer-bottom {
  background: #e3e3e3;
  border-top: 1px solid #dddddd;
  padding: 16px;
}

.footer-bottom p {
  font-size: 13px;
  text-align: center;
  margin: 0;
}
