.project-image img {
  width: 100%;
}

.description-container h3 {
  color: rgb(51, 119, 204);
  letter-spacing: -0.5px;
  margin-bottom: 5px;
}

.sub-heading {
  font-family: 'Raleway', Arial, Helvetica, sans-serif;
  color: #5e9516;
  font-size: 18px;
  font-weight: bold;
}

/* Desktop */
@media (min-width: 768px) {
  .description-container h3 {
    margin-top: 0;
  }
}
