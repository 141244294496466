@font-face {
  font-family: 'Lato-Light';
  src: local('Lato-Light'), url(./assets/fonts/Lato-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url(./assets/fonts/Raleway-Regular.ttf) format('truetype');
}

/* Remove bootstrap overflow/spacing on mobile */
@media (max-width: 768px) {
  div.row {
    margin-right: 0px;
    margin-left: 0px;
  }

  div.col-xs-6 {
    padding-left: 0;
    padding-right: 0;
  }
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  background: white;
}

p {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  color: #555555;
}

h1,
h2,
h3 {
  color: #515151;
}

h1 {
  font-family: 'Lato-Light', sans-serif;
  text-transform: uppercase;
  text-align: center;
  font-size: 40px;
  margin: 32px;
}

h3 {
  font-family: 'Raleway', Arial, Helvetica, sans-serif;
}

.navbar {
  margin: 0;
  background: #080808;
  border-radius: 0;
}

/* Desktop */
@media (min-width: 768px) {
  .navbar .nav > .active {
    border-bottom: 2px solid red;
  }
}
